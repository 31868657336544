/* src/Sidebar.css */
.hamburger {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  position: fixed; /* Fixed position */
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  z-index: 1100; /* Ensure the button is above other content */
}

.sidebar {
  width: 250px;
  background-color: #111;
  color: white;
  position: fixed; /* Fixed position */
  top: 0;
  left: -250px; /* Hidden by default */
  height: 100%;
  overflow: hidden;
  transition: left 0.3s;
  z-index: 1000; /* Ensure the sidebar overlays other content */
}

.sidebar.open {
  left: 0; /* Shown when open */
}

.close-btn {
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  float: right;
  margin: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: #575757;
}

.menu-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background 0.3s;
}

.menu-link:hover {
  background-color: #575757;
  color: white;
}

.navLocs {
  margin-left: 10px;
}

.form-group {
  margin-bottom: 1rem;
}
